import React from "react";
import { useNavigate } from "react-router-dom";

import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import TopHeader from "../../Components/TopHeader/TopHeader";
import CartItem from "../../Components/CartItem/CartItem";
import SearchWithButton from "../../Components/SearchWithButton/SearchWithButton";
import PriceDetails from "../../Components/PriceDetails/PriceDetails";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";

import "./style.css";

const Cart = () => {
  const navigate = useNavigate();
  return (
    <MobileLayout>
      <div style={{ paddingTop: 26 }} className="home-container">
        <TopHeader title="Cart" backRoute="store" />

        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          <CartItem />
          <CartItem />
          <CartItem />
        </div>

        <div>
          <SearchWithButton placeholder="Enter coupon code" title="ADD" />
        </div>

        <div className="card-container">
          <PriceDetails />
        </div>

        <ButtonDefault title="Continue" onClick={() => navigate("/address")} />
      </div>
    </MobileLayout>
  );
};

export default Cart;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import TopHeader from "../../Components/TopHeader/TopHeader";
import InputDefault from "../../Components/InputDefault/InputDefault";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";

import "./style.css";
import FullScreenLoader from "../../Components/FullScreenLoader/FullScreenLoader";
import { useSelector } from "react-redux";
import { FindUserByEmail, UpdateUserByEmail } from "../../Utils/FirebaseQuery";
import DropDown from "../../Components/DropDown/DropDown";
import { message } from "antd";

const Setup = () => {
  const navigate = useNavigate();
  const db = getFirestore();

  const userDetails = useSelector((state) => state.user.userDetails);

  const [loading, setLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    gender: "",
    whatsappNumber: "",
    address: "",
    vehicleNumber: "",
    vehicleMake: "",
    vehicleModel: "",
    bloodGroup: "",
    emergencyContactNumber: "",
  });

  useEffect(() => {
    FindUserByEmail(userDetails?.email).then((userData) => {
      if (userData) {
        setFormData({ ...formData, ...userData });
        setPageLoader(false);
      }
    });
  }, [userDetails?.email]);

  const handleSetup = async () => {
    setLoading(true);
    try {
      if (
        !formData.gender ||
        !formData.whatsappNumber ||
        !formData.address ||
        !formData.vehicleNumber ||
        !formData.vehicleMake ||
        !formData.vehicleModel ||
        !formData.bloodGroup ||
        !formData.emergencyContactNumber
      ) {
        return message.warning("Please fill all fields");
      }

      const result = await UpdateUserByEmail(userDetails?.email, formData);

      if (result.success) {
        message.success(result.message);
        navigate("/status", {
          state: {
            navigate: "/profile",
            title: "QR Code Setup Complete!",
            desc: "Your QR Code is now successfully set up. You’re all set to start using it!",
          },
        });
      } else {
        message.error(result.message);
      }
    } catch (error) {
      message.error("Faied to update user");
    } finally {
      setLoading(false);
    }
  };
  return (
    <MobileLayout>
      <FullScreenLoader loading={pageLoader} />
      <div style={{ paddingTop: 26 }} className="home-container">
        <TopHeader title="Scan" />

        <div>
          <p className="common-p">
            We value your privacy and are committed to protecting your personal
            information. Rest assured, your details and any related information
            will never be shared with anyone.
          </p>
          <br />
          <p className="common-p">
            Please fill out the form below to set up your{" "}
            <span className="span-red">ScanPing</span> QR Code
          </p>
        </div>

        <div className="setup-form">
          <InputDefault
            placeholder="Full name"
            value={formData.fullName}
            onChange={({ target }) =>
              setFormData({ ...formData, email: target.value })
            }
            disabled
          />
          <InputDefault
            placeholder="Email address"
            value={formData.email}
            onChange={({ target }) =>
              setFormData({ ...formData, email: target.value })
            }
            disabled
          />
          <DropDown
            placeholder={"Gender"}
            options={[
              {
                value: "Female",
                label: "Female",
              },
              {
                value: "Male",
                label: "Male",
              },
              {
                value: "Others",
                label: "Others",
              },
            ]}
            defaultValue={formData.gender}
            onChange={(value) => setFormData({ ...formData, gender: value })}
          />
          <InputDefault
            placeholder="WhatsApp number"
            value={formData.whatsappNumber}
            onChange={({ target }) =>
              /^\d*$/.test(target?.value) &&
              target?.value?.length <= 10 &&
              setFormData({ ...formData, whatsappNumber: target.value })
            }
          />
          <InputDefault
            placeholder="Address"
            value={formData.address}
            onChange={({ target }) =>
              setFormData({ ...formData, address: target.value })
            }
          />
          <InputDefault
            placeholder="Vehicle number"
            value={formData.vehicleNumber}
            onChange={({ target }) =>
              setFormData({ ...formData, vehicleNumber: target.value })
            }
          />
          <InputDefault
            placeholder="Vehicle make"
            value={formData.vehicleMake}
            onChange={({ target }) =>
              setFormData({ ...formData, vehicleMake: target.value })
            }
          />
          <InputDefault
            placeholder="Vehicle model"
            value={formData.vehicleModel}
            onChange={({ target }) =>
              setFormData({ ...formData, vehicleModel: target.value })
            }
          />
          <InputDefault
            placeholder="Blood group"
            value={formData.bloodGroup}
            onChange={({ target }) =>
              setFormData({ ...formData, bloodGroup: target.value })
            }
          />
          <InputDefault
            placeholder="Emergency contact number"
            value={formData.emergencyContactNumber}
            onChange={({ target }) =>
              /^\d*$/.test(target?.value) &&
              target?.value?.length <= 10 &&
              setFormData({ ...formData, emergencyContactNumber: target.value })
            }
          />
          <ButtonDefault
            title="Setup"
            onClick={handleSetup}
            loading={loading}
          />
        </div>
      </div>
    </MobileLayout>
  );
};

export default Setup;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cookie from "react-cookies";
import { message } from "antd";

import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import InputDefault from "../../Components/InputDefault/InputDefault";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";
import CheckboxDefault from "../../Components/CheckboxDefault/CheckboxDefault";

import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

import BrandLogo from "../../Assets/logo.svg";

import { GenerateMCToken, SendMCOTP } from "../../Utils/Reusables";
import { ValidateEmail, ValidateMobileNumber } from "../../Utils/ReuseFunction";

import "./style.css";

const Signup = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    password: "",
  });
  const [buttonLoader, setButtonLoader] = useState();
  const [termsChecked, setTermsChecked] = useState(false);

  const handleSignup = async () => {
    setButtonLoader(true); // Start the loader

    try {
      // Validate required fields
      if (
        !formData.fullName ||
        !formData.mobileNumber ||
        !formData.email ||
        !formData.password
      ) {
        message.warning("Please fill all fields");
        return;
      }

      // Validate terms acceptance
      if (!termsChecked) {
        message.warning("Please accept terms and policy");
        return;
      }

      if (!ValidateMobileNumber(formData.mobileNumber)) {
        message.warning("Enter a valid 10 digit mobile number");
        return;
      }

      if (!ValidateEmail(formData.email)) {
        message.warning("Enter a valid email");
        return;
      }

      // Check if user already exists
      const q = query(
        collection(db, "users"),
        where("mobileNumber", "==", formData.mobileNumber),
        where("email", "==", formData.email)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        try {
          // Generate MC Token and send OTP
          const token = await GenerateMCToken();
          const otpResponse = await SendMCOTP(
            token,
            formData.mobileNumber,
            "SMS"
          );

          if (otpResponse?.data?.message === "SUCCESS") {
            cookie.save("mc-v-id", otpResponse?.data?.data?.verificationId);
            message.success("OTP Sent!");
            navigate("/verify", {
              state: {
                userInfo: formData,
              },
            });
          } else {
            message.error("OTP not sent. Please try again.");
          }
        } catch (authError) {
          // Handle Firebase Authentication errors
          message.error(
            authError.message || "Authentication failed. Please try again."
          );
        }
      } else {
        message.error("User already exists!");
      }
    } catch (error) {
      console.log(error);
      
      message.error("Something went wrong. Try again.");
    } finally {
      setButtonLoader(false); // Ensure loader stops
    }
  };

  return (
    <MobileLayout>
      <div className="signup-container">
        <div style={{ alignSelf: "center" }}>
          <img src={BrandLogo} alt="brand-logo" style={{ width: 250 }} />
        </div>

        <div style={{ textAlign: "center" }}>
          <p className="common-title">Let’s get started 👇</p>
          <p style={{ marginTop: 10 }}>Join the ScanPing community today!</p>
        </div>

        <InputDefault
          placeholder="Full name"
          onChange={({ target }) =>
            setFormData({ ...formData, fullName: target.value })
          }
          value={formData.fullName}
        />
        <InputDefault
          placeholder="Email"
          onChange={({ target }) =>
            setFormData({ ...formData, email: target.value })
          }
          value={formData.email}
          type="email"
        />
        <InputDefault
          placeholder="Mobile number"
          onChange={({ target }) =>
            setFormData({ ...formData, mobileNumber: target.value })
          }
          value={formData.mobileNumber}
        />
        <InputDefault
          placeholder="Password"
          onChange={({ target }) =>
            setFormData({ ...formData, password: target.value })
          }
          value={formData.password}
          type="password"
        />

        <div className="sp-flex-align-centered" style={{ gap: 10 }}>
          <CheckboxDefault onChange={() => setTermsChecked(!termsChecked)} />{" "}
          <p>
            Agree with <Link to="/terms-of-use">Terms & Privacy Policy</Link>
          </p>
        </div>

        <ButtonDefault
          title="create account"
          size="large"
          onClick={handleSignup}
          loading={buttonLoader}
        />

        <center>
          <p>
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </center>
      </div>
    </MobileLayout>
  );
};

export default Signup;

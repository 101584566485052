import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";

const db = getFirestore();
const auth = getAuth();

export const FindUserByEmail = async (email) => {
  if (!email || typeof email !== "string") {
    console.error("Invalid email provided:", email);
    return null;
  }

  try {
    // Create a query against the "users" collection
    const q = query(collection(db, "users"), where("email", "==", email));

    // Execute the query
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("No matching documents.");
      return null;
    }

    // Get the first document from the query result
    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data();

    console.log("User found:", userData);
    return userData;
  } catch (error) {
    console.error("Error finding user:", error);
    return null;
  }
};

export const LogoutUser = async (navigate) => {
  try {
    await signOut(auth);
    // Redirect to login page or home page
    navigate("/login");
  } catch (error) {
    console.error("Error signing out: ", error);
  }
};

export const UpdateUserByEmail = async (email, newData) => {
  try {
    // Reference to the collection where users are stored
    const usersCollection = collection(db, "users");

    // Create a query to find the user by email
    const q = query(usersCollection, where("email", "==", email));

    // Execute the query
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("No user found with that email.");
      return;
    }

    // Update the first document found (assuming email is unique)
    const userDoc = querySnapshot.docs[0];
    const userDocRef = doc(db, "users", userDoc.id);

    // Update user data
    await updateDoc(userDocRef, newData);

    return { success: true, message: "Updated successfully!" };
  } catch (error) {
    console.error("Error updating user");
    return { success: false, message: "Error updating user data." };
  }
};

import React, { useState } from "react";
import cookie from "react-cookies";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";

import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";

import InputDefault from "../../Components/InputDefault/InputDefault";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";

import { GenerateMCToken, ValidateOTP } from "../../Utils/Reusables";

import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";

import BrandLogo from "../../Assets/logo.svg";

const OTP = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const auth = getAuth();

  const { userInfo } = location.state || {};

  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({ otp: "" });

  const handleVerifyOTP = async () => {
    setLoader(true);

    try {
      if (!formData.otp) {
        message.warning("Please enter the OTP");
        return;
      }

      const token = await GenerateMCToken();
      const validateOtp = await ValidateOTP(
        token,
        cookie.load("mc-v-id"),
        formData.otp
      );

      if (validateOtp?.response?.data?.message === "ALREADY_VERIFIED") {
        message.warning("Already verified");
      } else if (validateOtp?.data?.message === "SUCCESS") {
        // Create user with email and password using Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          userInfo.email,
          userInfo.password
        );

        if (userCredential.user) {
          await updateProfile(userCredential.user, {
            displayName: userInfo.fullName,
          });

          const now = new Date();

          // Add new user to Firestore
          await addDoc(collection(db, "users"), {
            uid: userCredential.user.uid,
            fullName: userInfo.fullName,
            mobileNumber: userInfo.mobileNumber,
            email: userInfo.email,
            createdAt: now.toISOString(),
            updatedAt: now.toISOString(),
          });
        }
        message.success("OTP verified");
        navigate("/");
      } else {
        message.error("Invalid OTP");
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  return (
    <MobileLayout>
      <div className="signup-container">
        <div style={{ alignSelf: "center" }}>
          <img src={BrandLogo} alt="brand-logo" style={{ width: 250 }} />
        </div>

        <div style={{ textAlign: "center" }}>
          <p className="common-title">Verify account 🔐</p>
          <p style={{ marginTop: 10 }}>We have sent the verification code</p>
        </div>

        <InputDefault
          placeholder="Enter verification code"
          value={formData.otp}
          onChange={({ target }) => setFormData({ otp: target.value })}
        />

        <ButtonDefault
          title="Verify"
          size="large"
          loading={loader}
          onClick={handleVerifyOTP}
        />
      </div>
    </MobileLayout>
  );
};

export default OTP;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import { QrReader } from "react-qr-reader";
import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import TopHeader from "../../Components/TopHeader/TopHeader";
import "./style.css";

const Scan = () => {
  const navigate = useNavigate();
  const [result, setResult] = useState("No result");
  const [facingMode, setFacingMode] = useState("environment"); // Default to back camera

  const handleScan = (data) => {
    if (data) {
      setResult(data.text);
      message.success(`QR Code scanned: ${data.text}`);
    }
  };

  const handleError = (err) => {
    // console.log(err);
    // message.error(`QR Scanner Error: ${err}`);
  };

  const toggleCamera = () => {
    setFacingMode((prev) => (prev === "environment" ? "user" : "environment"));
  };

  return (
    <MobileLayout>
      <div style={{ paddingTop: 26 }} className="home-container">
        <TopHeader title="Scan" />
        <div
          className="card-container"
          style={{ textAlign: "center", padding: "30px 20px" }}
        >
          <h2 className="scan-title">Scan QR Code</h2>
          <p className="scan-desc">
            Scan the existing QR Code to contact or to setup your new ScanPing
            Tags
          </p>

          <QrReader
            onResult={(result, error) => {
              if (result) handleScan(result);
              if (error) handleError(error);
            }}
            constraints={{ facingMode }}
            style={{ height: 240, width: 320, borderRadius: 20 }}
          />

          <Button onClick={toggleCamera} style={{ marginTop: 20 }}>
            Switch Camera
          </Button>
        </div>

        <Button onClick={() => navigate("/setup")}>GO TO SETUP (TEMP)</Button>
      </div>
    </MobileLayout>
  );
};

export default Scan;

import React from "react";
import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import TopHeader from "../../Components/TopHeader/TopHeader";
import SearchWithButton from "../../Components/SearchWithButton/SearchWithButton";

import SearchIcon from "../../Assets/Images/SVG/referrak-link.svg";

import "./style.css";
import { Tag } from "antd";

const Referral = () => {
  return (
    <MobileLayout>
      <div style={{ paddingTop: 26 }} className="home-container">
        <TopHeader title="Referral" backRoute="profile" />

        <p className="common-p">
          Invite friends to join ScanPing and earn rewards for each successful
          referral! Spread the word and help others enjoy seamless, secure
          communication.
        </p>

        <h1>Refer and Earn</h1>

        <SearchWithButton
          placeholder="XXX-XXX-XXXX"
          buttonIcon={<img src={SearchIcon} alt="icon" />}
        />

        <div className="card-container referral-container">
          <h3 className="common-p" style={{ marginBottom: 26 }}>
            Invited friends
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 16,
              justifyContent: "space-between",
            }}
          >
            <div>
              <p className="common-p">Yadunand Pavithran</p>
              <p className="common-small-p">yadunand@gmail.com</p>
            </div>
            <div>
              <Tag color="success" style={{ padding: "10px 10px 10px 10px" }}>
                Account Created
              </Tag>
            </div>
          </div>
          <div className="curve-container">
            <h1>No referrals found!</h1>
            <p className="referral-container-p">
              Share ScanPing with your friends or family earn cash or get
              coupons.
            </p>
          </div>
        </div>
      </div>
    </MobileLayout>
  );
};

export default Referral;

import React from "react";
import { Badge, Button } from "antd";
import { useNavigate } from "react-router-dom";

import ProductImage from "../../Assets/Images/PNG/product-1.png";

import "./style.css";

const ProductCard = ({ offer }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Badge.Ribbon
        text={offer}
        color="red"
        style={{ display: offer ? "unset" : "none" }}
      >
        <div
          className="card-container product-item"
          onClick={() => navigate("/product/vehicle-parking-tag")}
        >
          <img
            src={ProductImage}
            alt="product-image"
            className="product-image"
          />
          <div>
            <h2>Vehicle Parking Tag</h2>
            <p className="product-item-text">
              This tag allows to contact the vehicle owner even when they are
              away, without revealing their contact information.
            </p>
          </div>
          <div className="product-controller">
            <p className="product-offer-price">
              <span className="product-offer-strike">₹ 499.00</span>₹ 249.00
            </p>
          </div>
          <div>
            <Button className="add-to-cart-btn" style={{ width: "100%" }}>
              ADD TO CART
            </Button>
          </div>
        </div>
      </Badge.Ribbon>
    </div>
  );
};

export default ProductCard;

import { Select } from "antd";
import React from "react";

const DropDown = ({ options, defaultValue, placeholder, onChange, value }) => {
  return (
    <Select
      style={{ fontSize: 14 }}
      placeholder={placeholder}
      defaultValue={defaultValue}
      className="defaultInput-style"
      options={options}
      onChange={onChange}
      value={value}
    />
  );
};

export default DropDown;

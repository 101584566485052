import axios from "axios";
import { GlobalConfig } from "./GlobalConfig";

export const GenerateMCToken = async () => {
  try {
    const response = await axios.get(
      `${GlobalConfig.MC_BSE_URL}/auth/v1/authentication/token?customerId=${GlobalConfig.MC_C}&key=${GlobalConfig.MC_K}&scope=NEW&country=91`
    );
    return response?.data?.token;
  } catch (err) {
    console.error("Error generating MC Token:", err);
    return err;
  }
};

export const SendMCOTP = async (authToken, mobileNumber, flowType) => {
  try {
    const response = await axios.post(
      `${GlobalConfig.MC_BSE_URL}/verification/v3/send?countryCode=91&flowType=${flowType}&mobileNumber=${mobileNumber}`,
      null,
      {
        headers: {
          authToken,
        },
      }
    );
    return response;
  } catch (err) {
    console.error("Error sending OTP:", err);
    return err;
  }
};

export const ValidateOTP = async (authToken, vid, code) => {
  try {
    const response = await axios.get(
      `${GlobalConfig.MC_BSE_URL}/verification/v3/validateOtp?verificationId=${vid}&code=${code}`,
      {
        headers: {
          authToken,
        },
      }
    );
    return response;
  } catch (err) {
    console.error("Error validating OTP:", err);
    return err;
  }
};

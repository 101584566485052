import React from "react";

import "./style.css";

const Quote = () => {
  return (
    <div className="quote-container">
      <h4>Enhance Your Connectivity!</h4>
      <p>Bengaluru-Made, Made with ❤️</p>
    </div>
  );
};

export default Quote;

import { useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";

import TopHeader from "./Components/TopHeader/TopHeader";
import BottomNavBar from "./Components/BottomNavBar/BottomNavBar";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import Signup from "./Pages/Signup/Signup";
import Login from "./Pages/Login/Login";
import OTP from "./Pages/OTP/OTP";
import Home from "./Pages/Home/Home";
import Search from "./Pages/Search/Search";
import ProductDetail from "./Pages/ProductDetail/ProductDetail";
import Store from "./Pages/Store/Store";
import Cart from "./Pages/Cart/Cart";
import Address from "./Pages/Address/Address";
import Payment from "./Pages/Payment/Payment";
import Status from "./Pages/Status/Status";
import Scan from "./Pages/Scan/Scan";
import Setup from "./Pages/Setup/Setup";
import Profile from "./Pages/Profile/Profile";
import ProfileSettings from "./Pages/ProfileSettings/ProfileSettings";
import Referral from "./Pages/Referral/Referral";

import "./index.css";
import "./App.css";
import "./antd-custom.css";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import { useDispatch } from "react-redux";
import { setUserDetails } from "./redux/reducers/userReducer";

function App() {
  const dispatch = useDispatch();
  const appRef = useRef(null);
  const auth = getAuth();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      dispatch(
        setUserDetails({
          displayName: user?.displayName,
          email: user?.email,
          accessToken: user?.accessToken,
        })
      );
      setIsAuthenticated(!!user);
    });
    return () => unsubscribe();
  }, [auth, dispatch]);

  const toggleFullScreen = () => {
    if (appRef.current) {
      if (appRef.current.requestFullscreen) {
        appRef.current.requestFullscreen();
      } else if (appRef.current.webkitRequestFullscreen) {
        /* Safari */
        appRef.current.webkitRequestFullscreen();
      }
    }
  };
  return (
    <main className="main-wrapper">
      <div className="mobile-view">
        {/* <TopHeader title="Hello" /> */}
        <Routes>
          {/* START OF AUTH SCREENS */}
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify" element={<OTP />} />
          {/* END OF AUTH SCREENS */}

          {/* START OF HOME SCREENS */}
          <Route path="/" element={<PrivateRoute element={Home} />} />
          <Route path="/search" element={<Search />} />
          {/* END OF HOME SCREENS */}

          {/* START OF ECOMMERCE SCREENS */}
          <Route path="/store" element={<Store />} />
          <Route path="/product/:name" element={<ProductDetail />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/address" element={<Address />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/status" element={<Status />} />
          {/* END OF ECOMMERCE SCREENS */}

          {/* START OF SCAN SCREENS */}
          <Route path="/scan" element={<Scan />} />
          <Route path="/setup" element={<Setup />} />
          {/* END OF SCAN SCREENS */}

          {/* START OF PROFILE SCREENS */}
          <Route path="/profile" element={<PrivateRoute element={Profile} />} />
          <Route path="/profile-settings" element={<ProfileSettings />} />
          {/* END OF PROFILE SCREENS */}

          {/* START OF REFERRAL SCREENS */}
          <Route path="/referral" element={<Referral />} />
          {/* END OF REFERRAL SCREENS */}
        </Routes>
        <BottomNavBar />
      </div>
    </main>
  );
}

export default App;

import React from "react";

const ScanIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10.8889H10.8889V1.99997H2V10.8889ZM4.22222 4.22219H8.66667V8.66664H4.22222V4.22219ZM2 22H10.8889V13.1111H2V22ZM4.22222 15.3333H8.66667V19.7777H4.22222V15.3333ZM13.1111 1.99997V10.8889H22V1.99997H13.1111ZM19.7778 8.66664H15.3333V4.22219H19.7778V8.66664ZM19.7778 19.7777H22V22H19.7778V19.7777ZM13.1111 13.1111H15.3333V15.3333H13.1111V13.1111ZM15.3333 15.3333H17.5556V17.5555H15.3333V15.3333ZM13.1111 17.5555H15.3333V19.7777H13.1111V17.5555ZM15.3333 19.7777H17.5556V22H15.3333V19.7777ZM17.5556 17.5555H19.7778V19.7777H17.5556V17.5555ZM17.5556 13.1111H19.7778V15.3333H17.5556V13.1111ZM19.7778 15.3333H22V17.5555H19.7778V15.3333Z"
        fill={color}
      />
    </svg>
  );
};

export default ScanIcon;

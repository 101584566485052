import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";

import Image from "../../Assets/Images/PNG/product-big.png";

const CartItem = () => {
  return (
    <div
      className="card-container"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 10,
        }}
      >
        <img src={Image} alt="product-image" style={{ width: 100 }} />

        <div className="cart-details">
          <p>Vehicle Parking Tag Sticker</p>
          <div className="cart-controller">
            <Button style={{ width: 30, fontSize: 20, fontWeight: "bolder" }}>
              +
            </Button>
            <Input style={{ width: 30 }} value={1} />
            <Button style={{ width: 30, fontSize: 20, fontWeight: "bolder" }}>
              -
            </Button>
          </div>
        </div>
      </div>
      <CloseOutlined />
    </div>
  );
};

export default CartItem;

import React from "react";

const CartIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99994 1.99988L2.99994 5.99988V19.9999C2.99994 20.5303 3.21065 21.039 3.58573 21.4141C3.9608 21.7892 4.46951 21.9999 4.99994 21.9999H18.9999C19.5304 21.9999 20.0391 21.7892 20.4142 21.4141C20.7892 21.039 20.9999 20.5303 20.9999 19.9999V5.99988L17.9999 1.99988H5.99994Z"
        stroke={color}
        stroke-width="1.99832"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.99994 5.99988H20.9999"
        stroke={color}
        stroke-width="1.99832"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9999 9.99988C15.9999 11.0607 15.5785 12.0782 14.8284 12.8283C14.0782 13.5785 13.0608 13.9999 11.9999 13.9999C10.9391 13.9999 9.92166 13.5785 9.17151 12.8283C8.42137 12.0782 7.99994 11.0607 7.99994 9.99988"
        stroke={color}
        stroke-width="1.99832"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CartIcon;

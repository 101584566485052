import React from "react";
import { Button } from "antd";

import "./style.css";

const ButtonDefault = ({ ...props }) => {
  return (
    <Button className="defaultButton-style" {...props}>
      {props.title}
    </Button>
  );
};

export default ButtonDefault;

import React from "react";
import { useNavigate } from "react-router-dom";

import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import TopHeader from "../../Components/TopHeader/TopHeader";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";
import RadioDefault from "../../Components/RadioDefault/RadioDefault";

import PaymentIcon from "../../Assets/Images/SVG/payment-icons.svg";

const Payment = () => {
  const navigate = useNavigate();
  return (
    <MobileLayout>
      <div style={{ paddingTop: 26 }} className="home-container">
        <TopHeader title="Payment" backRoute="address" />

        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          <RadioDefault title="Pay online" />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={PaymentIcon} alt="payments" style={{ width: 200 }} />
          </div>
          <RadioDefault title="Cash on delivery" />
        </div>

        <ButtonDefault
          title="Place order"
          onClick={() => navigate("/status")}
        />
      </div>
    </MobileLayout>
  );
};

export default Payment;

import React, { useEffect } from "react";
import Lottie from "lottie-react";
import { useLocation, useNavigate } from "react-router-dom";

import SuccessAnimation from "../../Assets/Animations/success.json";

const Status = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { state } = location;

  useEffect(() => {
    setTimeout(() => {
      navigate(`${state?.navigate}`);
    }, 3000);
  }, [navigate, state?.navigate]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Lottie
          animationData={SuccessAnimation}
          loop={true}
          style={{ width: 300, marginTop: 50 }}
        />
      </div>
      <div style={{ textAlign: "center", padding: "0 26px" }}>
        <h2>{state?.title}</h2>
        <p style={{ marginTop: 20 }}>{state?.desc}</p>
      </div>
    </>
  );
};

export default Status;

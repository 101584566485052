import React from "react";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import DrawerIcon from "../../Assets/Images/SVG/drawer-icon.svg";

const TopHeader = ({ title, isDrawer, backRoute }) => {
  const navigate = useNavigate();
  return (
    <div className="sp-flex-align-justify-between">
      {backRoute ? (
        <div className="sp-flex-align-centered" style={{ gap: 10 }}>
          <Button
            icon={<LeftOutlined />}
            onClick={() => navigate(`/${backRoute}`)}
          />
          <p className="page-heading">
            {title}
            <span className="span-red">.</span>
          </p>
        </div>
      ) : (
        <p className="page-heading">
          {title}
          <span className="span-red">.</span>
        </p>
      )}

      <img src={DrawerIcon} alt="drawer-icon" />
    </div>
  );
};

export default TopHeader;

import React from "react";
import { useNavigate } from "react-router-dom";

import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import TopHeader from "../../Components/TopHeader/TopHeader";
import InputDefault from "../../Components/InputDefault/InputDefault";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";

const Address = () => {
  const navigate = useNavigate();
  return (
    <MobileLayout>
      <div style={{ paddingTop: 26 }} className="home-container">
        <TopHeader title="Address" backRoute="cart" />

        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          <InputDefault placeholder="Full name" />
          <InputDefault placeholder="Address Line 1" />
          <InputDefault placeholder="Address Line 2" />
          <InputDefault placeholder="Landmark" />
          <InputDefault placeholder="Pincode" />
          <InputDefault placeholder="Alternative contact" />
        </div>

        <ButtonDefault title="Continue" onClick={() => navigate("/payment")} />
      </div>
    </MobileLayout>
  );
};

export default Address;

import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const FullScreenLoader = ({ loading }) => {
  return (
    <Spin
      indicator={<LoadingOutlined style={{ color: "#ee3f37" }} spin />}
      size="large"
      fullscreen
      spinning={loading}
    />
  );
};

export default FullScreenLoader;

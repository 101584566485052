import React from "react";
import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";

import TopHeader from "../../Components/TopHeader/TopHeader";
import SearchWithButton from "../../Components/SearchWithButton/SearchWithButton";
import PromotionCarousal from "../../Components/PromotionCarousal/PromotionCarousal";
import ServiceCard from "../../Components/ServiceCard/ServiceCard";

import CarIcon from "../../Assets/Images/SVG/directions_car.svg";
import DoorIcon from "../../Assets/Images/SVG/door_open.svg";
import PetIcon from "../../Assets/Images/SVG/pets.svg";
import EssentialIcon from "../../Assets/Images/SVG/qr_code_scanner.svg";

import "./style.css";
import Quote from "../../Components/Quote/Quote";

const Home = () => {
  return (
    <MobileLayout>
      <div style={{ paddingTop: 26 }} className="home-container">
        <TopHeader title="Hello" />

        <div>
          <SearchWithButton placeholder="Search nearest store..." />
        </div>

        <div className="card-container" style={{ height: 140 }}>
          <PromotionCarousal />
        </div>

        <div className="services-card-container">
          <ServiceCard
            title="Parking Tag"
            icon={CarIcon}
            details={[
              "Instant QR notifications",
              "Anonymous messaging",
              "Multi-use scan tags",
            ]}
          />
          <ServiceCard
            title="House Door Tag"
            icon={DoorIcon}
            details={[
              "Visitor notifications",
              "Delivery updates",
              "Secure communication",
            ]}
          />
          <ServiceCard
            title="Pet Tag"
            icon={PetIcon}
            details={[
              "Lost pet location alert",
              "Owner contact info",
              "Pet safety messages",
            ]}
          />
          <ServiceCard
            title="Parking Tag"
            icon={EssentialIcon}
            details={[
              "Lost Item Alert",
              "Owner Identification",
              "Quick Recovery Help",
            ]}
          />
        </div>

        <div>
          <Quote />
        </div>
      </div>
    </MobileLayout>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LogoutOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import TopHeader from "../../Components/TopHeader/TopHeader";
import FullScreenLoader from "../../Components/FullScreenLoader/FullScreenLoader";

import ProfileIcon from "../../Assets/Images/SVG/profile-icon.svg";
import ReferralIcon from "../../Assets/Images/SVG/referral-icon.svg";
import AchievementIcon from "../../Assets/Images/SVG/acheievement-icon.svg";
import PrivacyIcon from "../../Assets/Images/SVG/privacy-icon.svg";
import SupportIcon from "../../Assets/Images/SVG/support-icon.svg";

import { getAuth } from "firebase/auth";

import "./style.css";
import { LogoutUser } from "../../Utils/FirebaseQuery";
import SwitchSlide from "../../Components/SwitchSlide/SwitchSlide";

const Profile = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      setUser({
        displayName: currentUser.displayName,
        email: currentUser.email,
        uid: currentUser.uid,
      });
    } else {
      setUser(null);
    }

    setLoading(false);
  }, []);

  return (
    <MobileLayout>
      <FullScreenLoader loading={loading} />
      <div style={{ paddingTop: 26 }} className="home-container">
        <TopHeader title="Scan" />

        <p className="common-p">
          Update your profile information for seamless, secure communication.
        </p>

        <div className="sp-flex-align-justify-between ">
          <Avatar shape="circle" style={{ width: 80, height: 80 }}>
            {user?.displayName?.slice(0, 1)}
          </Avatar>
          <div>
            <p className="profile-user">{user?.displayName}</p>
            <p className="profile-phone">{user?.email}</p>
          </div>
          <EditOutlined />
        </div>

        <center>
          <p>
            Your tag got scanned <span className="span-red">32</span> times.
          </p>
        </center>

        <div className="card-container profile-links toggle-features-container">
          <div className="sp-flex-align-justify-between">
            <p>Pause Scanping Tag</p>
            <SwitchSlide onChange={() => true} />
          </div>
          <div className="sp-flex-align-justify-between">
            <p>Upload images and videos</p>
            <SwitchSlide onChange={() => true} />
          </div>
          <div className="sp-flex-align-justify-between">
            <p>Enable WhatsApp notification</p>
            <SwitchSlide onChange={() => true} />
          </div>
          <div className="sp-flex-align-justify-between">
            <p>Enable NFC </p>
            <SwitchSlide onChange={() => true} />
          </div>
        </div>

        <div className="card-container profile-links">
          <p className="card-title">Account settings</p>
          <div
            className="sp-flex-align-justify-between profile-link-container"
            onClick={() => navigate("/profile-settings")}
          >
            <div className="sp-flex-align-centered" style={{ gap: 16 }}>
              <img src={ProfileIcon} alt="Profile" />
              <p className="profile-link-title">Personal Details</p>
            </div>
            <div>
              <RightOutlined color="#5B5B5B" />
            </div>
          </div>
          <div
            className="sp-flex-align-justify-between profile-link-container"
            onClick={() => navigate("/referral")}
          >
            <div className="sp-flex-align-centered" style={{ gap: 16 }}>
              <img src={ReferralIcon} alt="Profile" />
              <p className="profile-link-title">Referral</p>
            </div>
            <div>
              <RightOutlined color="#5B5B5B" />
            </div>
          </div>
          <div className="sp-flex-align-justify-between profile-link-container">
            <div className="sp-flex-align-centered" style={{ gap: 16 }}>
              <img src={AchievementIcon} alt="Achievement" />
              <p className="profile-link-title">Achievements</p>
            </div>
            <div>
              <RightOutlined color="#5B5B5B" />
            </div>
          </div>
          <div className="sp-flex-align-justify-between profile-link-container">
            <div className="sp-flex-align-centered" style={{ gap: 16 }}>
              <img src={PrivacyIcon} alt="Privacy" />
              <p className="profile-link-title">Privacy</p>
            </div>
            <div>
              <RightOutlined color="#5B5B5B" />
            </div>
          </div>
          <div className="sp-flex-align-justify-between profile-link-container">
            <div className="sp-flex-align-centered" style={{ gap: 16 }}>
              <img src={SupportIcon} alt="Support" />
              <p className="profile-link-title">Support</p>
            </div>
            <div>
              <RightOutlined color="#5B5B5B" />
            </div>
          </div>
        </div>

        <div className="card-container profile-links">
          <p className="card-title">My account</p>

          <div style={{ marginTop: 10 }}>
            <LogoutOutlined className="span-red" />
            <Link
              className="span-red"
              style={{ marginLeft: 6 }}
              onClick={() => LogoutUser(navigate)}
            >
              Logout
            </Link>
          </div>
          <div style={{ marginTop: 10 }}>
            <DeleteOutlined className="span-red" />
            <Link className="span-red" style={{ marginLeft: 6 }}>
              Delete account
            </Link>
          </div>
        </div>
      </div>
    </MobileLayout>
  );
};

export default Profile;

import React from "react";
import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import TopHeader from "../../Components/TopHeader/TopHeader";
import SearchWithButton from "../../Components/SearchWithButton/SearchWithButton";
import SearchResultCard from "../../Components/SearchResultCard/SearchResultCard";

const Search = () => {
  return (
    <MobileLayout>
      <div style={{ paddingTop: 26 }} className="home-container">
        <TopHeader title="Search" />

        <div>
          <SearchWithButton placeholder="Search nearest store..." />
        </div>

        <div>
          <SearchResultCard />
        </div>
      </div>
    </MobileLayout>
  );
};

export default Search;

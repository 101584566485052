import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, signInWithEmailAndPassword } from "../../firebase";

import InputDefault from "../../Components/InputDefault/InputDefault";
import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";

import BrandLogo from "../../Assets/logo.svg";
import { message } from "antd";

const Login = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });

  const handleLogin = async () => {
    setLoader(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      if (user) {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      message.error("Please check your email and password.");
    } finally {
      setLoader(false);
    }
  };

  return (
    <MobileLayout>
      <div className="signup-container">
        <div style={{ alignSelf: "center" }}>
          <img src={BrandLogo} alt="brand-logo" style={{ width: 250 }} />
        </div>

        <div style={{ textAlign: "center" }}>
          <p className="common-title">Welcome back 👋</p>
        </div>

        <InputDefault
          placeholder="Email"
          value={formData.email}
          onChange={({ target }) =>
            setFormData({ ...formData, email: target.value })
          }
          type="email"
        />

        <InputDefault
          placeholder="Password"
          value={formData.password}
          onChange={({ target }) =>
            setFormData({ ...formData, password: target.value })
          }
          type="password"
        />

        <ButtonDefault
          title="Login"
          size="large"
          loading={loader}
          onClick={handleLogin}
        />

        <center>
          <p>
            Don’t have an account? <Link to="/signup">Signup</Link>
          </p>
        </center>
      </div>
    </MobileLayout>
  );
};

export default Login;

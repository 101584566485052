import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Collapse, Rate } from "antd";
import { useNavigate } from "react-router-dom";

import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import TopHeader from "../../Components/TopHeader/TopHeader";

import ProductBig from "../../Assets/Images/PNG/product-big.png";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./style.css";

const ProductDetail = () => {
  const navigate = useNavigate();
  const text =
    "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.";
  const items = [
    {
      key: "1",
      label: "This is panel header 1",
      children: <p>{text}</p>,
    },
    {
      key: "2",
      label: "This is panel header 2",
      children: <p>{text}</p>,
    },
    {
      key: "3",
      label: "This is panel header 3",
      children: <p>{text}</p>,
    },
  ];
  return (
    <MobileLayout>
      <div
        style={{ paddingTop: 26, paddingBottom: 150 }}
        className="home-container"
      >
        <TopHeader title="Store" backRoute="/store" />

        <div>
          <Carousel
            autoPlay={true}
            showThumbs={false}
            swipeable={true}
            showArrows={false}
            showStatus={false}
            infiniteLoop
          >
            <div>
              <img src={ProductBig} alt="product-image" />
            </div>
            <div>
              <img src={ProductBig} alt="product-image" />
            </div>
          </Carousel>
        </div>

        <div className="detail-top-container">
          <h1 className="detail-title">Vehicle Parking Tag - Sticker</h1>
          <div>
            <p className="detail-price">₹249.00</p>
            <Rate
              count={5}
              defaultValue={5}
              disabled
              style={{ fontSize: 12, display: "flex", gap: 0, marginTop: 6 }}
            />
          </div>
        </div>

        <div className="desc-container">
          <div>
            <p className="desc-title">Description</p>
            <p className="desc-desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
          <div>
            <p className="desc-title">Size</p>
            <p className="desc-desc">2 X 4 inch</p>
          </div>
          <div>
            <p className="desc-title">Type</p>
            <p className="desc-desc">Vinyl</p>
          </div>
          <div>
            <p className="desc-title">Features</p>
            <Collapse
              defaultActiveKey={["1"]}
              ghost
              items={items}
              style={{ fontSize: 16 }}
            />
          </div>
        </div>

        <div className="floating-button">
          <ButtonDefault
            title="ADD TO CART"
            onClick={() => navigate("/cart")}
          />
        </div>
      </div>
    </MobileLayout>
  );
};

export default ProductDetail;

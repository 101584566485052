import React from "react";

const HomeIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.99969 9.00002L11.9997 2.00002L20.9997 9.00002V20C20.9997 20.5304 20.789 21.0392 20.4139 21.4142C20.0388 21.7893 19.5301 22 18.9997 22H4.99969C4.46926 22 3.96055 21.7893 3.58548 21.4142C3.21041 21.0392 2.99969 20.5304 2.99969 20V9.00002Z"
        stroke={color}
        stroke-width="1.99832"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;

import React from "react";
import { Checkbox } from "antd";

import "./style.css";

const CheckboxDefault = ({ ...props }) => {
  return <Checkbox className="checkBox-style" {...props} />;
};

export default CheckboxDefault;

import React from "react";
import { Button } from "antd";

import "./style.css";

const BlackButton = ({ ...props }) => {
  return (
    <Button {...props} className="buttonBlack-style">
      {props.title}
    </Button>
  );
};

export default BlackButton;

import React from "react";
import { Link, useLocation } from "react-router-dom";

import HomeIcon from "../../Assets/JSX/HomeIcon";
import CarIcon from "../../Assets/JSX/CartIcon";
import ScanIcon from "../../Assets/JSX/ScanIcon";
import ProfileIcon from "../../Assets/JSX/ProfileIcon";
import NotificationIcon from "../../Assets/JSX/NotificationIcon";

import "./style.css";

const BottomNavBar = () => {
  const location = useLocation();
  const getColor = (path) => {
    return location.pathname === path ? "#EE3F37" : "#121212";
  };
  return (
    <div className="bottom-navbar">
      <Link to="/">
        <HomeIcon color={getColor("/")} />
      </Link>
      <Link to="/store">
        <CarIcon color={getColor("/store")} />
      </Link>
      <Link to="/scan">
        <ScanIcon color={getColor("/scan")} />
      </Link>
      <Link to="/profile">
        <ProfileIcon color={getColor("/profile")} />
      </Link>
      <Link to="/notification">
        <NotificationIcon color={getColor("/notification")} />
      </Link>
    </div>
  );
};

export default BottomNavBar;

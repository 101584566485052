import React from "react";
import LocationIcon from "../../Assets/Images/SVG/location-bg.svg";
import NotFoundIcon from "../../Assets/Images/SVG/not-found.svg";

import "./style.css";

const SearchResultCard = () => {
  return (
    <>
      <div className="card-container search-container">
        <img src={LocationIcon} alt="location-icon" />
        <div>
          <h4>Maha Bazar</h4>
          <p>
            #2, New BEL Road,RMV 2nd Stage, Devasandra, M S R Nagar, R.M.V. 2nd
            Stage, Bengaluru
          </p>
        </div>
      </div>
      <div className="search-no-result">
        <img src={NotFoundIcon} alt="not-found-icon" />
        <p>Ops! No results found 🥺</p>
      </div>
    </>
  );
};

export default SearchResultCard;

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import "./style.css";

const PromotionCarousal = () => {
  return (
    <Carousel
      autoPlay={true}
      showThumbs={false}
      swipeable={true}
      showArrows={false}
      showStatus={false}
      showIndicators={false}
    >
      <div className="card-1">
        <p className="offer-slide-p">Refer and get</p>
        <h3 className="offer-slide-h3">50% OFF*</h3>
        <button className="offer-slide-button">Grab offer</button>
      </div>
      <div className="card-2">
        <p className="offer-slide-p">Refer and get</p>
        <h3 className="offer-slide-h3">50% OFF*</h3>
        <button className="offer-slide-button">Grab offer</button>
      </div>
    </Carousel>
  );
};

export default PromotionCarousal;

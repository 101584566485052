// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD_0-ezMSto3ETtukGU0iSZcahNv8oxOi8",
  authDomain: "scanping-app.firebaseapp.com",
  projectId: "scanping-app",
  storageBucket: "scanping-app.appspot.com",
  messagingSenderId: "575242720577",
  appId: "1:575242720577:web:b0be90465b4d188056bdc0",
  measurementId: "G-37KCGLY0ZY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);

// Export the necessary functions and objects
export { auth, db, signInWithEmailAndPassword };

import React from "react";
import { Tag } from "antd";
import { useNavigate } from "react-router-dom";

import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import TopHeader from "../../Components/TopHeader/TopHeader";
import SearchWithButton from "../../Components/SearchWithButton/SearchWithButton";
import PromotionCarousal from "../../Components/PromotionCarousal/PromotionCarousal";
import ProductCard from "../../Components/ProductCard/ProductCard";

import "./style.css";

const tagsData = ["All", "Vehicle", "House", "Pets", "Essentials"];

const Store = () => {
  const navigate = useNavigate();
  const [selectedTags, setSelectedTags] = React.useState("All");
  const handleChange = (tag) => {
    setSelectedTags(tag);
  };
  return (
    <MobileLayout>
      <div style={{ paddingTop: 26 }} className="home-container">
        <TopHeader title="Store" />

        <div className="card-container" style={{ height: 140 }}>
          <PromotionCarousal />
        </div>

        <div>
          <SearchWithButton placeholder="Search product..." prefixIcon />
        </div>

        <div className="search-tag-container">
          {tagsData.map((tag) => (
            <Tag.CheckableTag
              key={tag}
              checked={selectedTags.includes(tag)}
              onChange={(checked) => handleChange(tag, checked)}
            >
              {tag}
            </Tag.CheckableTag>
          ))}
        </div>

        <div className="product-list-container">
          <ProductCard offer="50% OFF" />
          <ProductCard />
        </div>
      </div>
    </MobileLayout>
  );
};

export default Store;

import React from "react";
import "./style.css";

const PriceDetails = () => {
  return (
    <div class="invoice">
      <div class="line-item">
        <span>Subtotal</span>
        <span>₹ 249.00</span>
      </div>
      <div class="line-item">
        <span>GST</span>
        <span>₹ 19.00</span>
      </div>
      <div class="line-item">
        <span>Delivery Charge</span>
        <span>₹ 49.00</span>
      </div>
      <div class="line-item">
        <span>Discount</span>
        <span>₹ 0.00</span>
      </div>
      <div class="line-item total">
        <span>TOTAL</span>
        <span>₹ 349.00</span>
      </div>
    </div>
  );
};

export default PriceDetails;

import React from "react";

import "./style.css";

const ServiceCard = ({ icon, title, details }) => {
  return (
    <div className="card-container box">
      <img src={icon} alt="service-icon" />
      <h3>{title}</h3>
      <ol>
        {details?.map((ele, i) => {
          return <li key={i}>{ele}</li>;
        })}
      </ol>
    </div>
  );
};

export default ServiceCard;

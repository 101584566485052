import React from "react";

const ProfileIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 21.0001V19.0001C20 17.9392 19.5786 16.9218 18.8284 16.1716C18.0783 15.4215 17.0609 15.0001 16 15.0001H8C6.93913 15.0001 5.92172 15.4215 5.17157 16.1716C4.42143 16.9218 4 17.9392 4 19.0001V21.0001"
        stroke={color}
        stroke-width="1.99832"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 11.0001C14.2091 11.0001 16 9.20921 16 7.00008C16 4.79094 14.2091 3.00008 12 3.00008C9.79086 3.00008 8 4.79094 8 7.00008C8 9.20921 9.79086 11.0001 12 11.0001Z"
        stroke={color}
        stroke-width="1.99832"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ProfileIcon;

import React from "react";
import { useNavigate } from "react-router-dom";

import MobileLayout from "../../Layouts/MobileLayout/MobileLayout";
import TopHeader from "../../Components/TopHeader/TopHeader";
import InputDefault from "../../Components/InputDefault/InputDefault";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";

const ProfileSettings = () => {
  const navigate = useNavigate();
  return (
    <MobileLayout>
      <div style={{ paddingTop: 26 }} className="home-container">
        <TopHeader title="Scan" />

        <div>
          <p className="common-p ">
            Update your profile information for seamless, secure communication.
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16,
              marginTop: 26,
            }}
          >
            <InputDefault placeholder="Full name" />
            <InputDefault placeholder="Email Adddress" />
            <InputDefault placeholder="Phone number" />
            <InputDefault placeholder="Address" />
            <InputDefault placeholder="Vehicle registration number" />
            <InputDefault placeholder="Vehicle brand" />
            <InputDefault placeholder="Vehicle model" />
            <InputDefault placeholder="Blood group" />
            <InputDefault placeholder="Emergency contact number" />
            <ButtonDefault
              title="Update"
              onClick={() => navigate("/profile")}
            />
          </div>
        </div>
      </div>
    </MobileLayout>
  );
};

export default ProfileSettings;

import React from "react";
import { Radio } from "antd";

import "./style.css";

const RadioDefault = ({ title }) => {
  return (
    <div className="radio-style">
      <p>{title}</p>
      <Radio />
    </div>
  );
};

export default RadioDefault;

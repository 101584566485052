import React from "react";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";

import BlackButton from "../BlackButton/BlackButton";

import SearchIcon from "../../Assets/Images/SVG/location_search.svg";
import SearchIconTwo from "../../Assets/Images/SVG/search-icon.svg";

import "./style.css";

const SearchWithButton = ({ placeholder, prefixIcon, title, buttonIcon }) => {
  const navigate = useNavigate();
  return (
    <div className="sp-flex-align-justify-between" style={{ gap: 12 }}>
      <Select
        style={{ flex: 10 }}
        className="searchWithButton-style"
        showSearch
        suffixIcon={false}
        placeholder={placeholder}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={[
          {
            value: "1",
            label: "Jack",
          },
          {
            value: "2",
            label: "Lucy",
          },
          {
            value: "3",
            label: "Tom",
          },
        ]}
      />

      <BlackButton
        style={{ flex: 1 }}
        title={
          title ? (
            <span>{title}</span>
          ) : buttonIcon ? (
            buttonIcon
          ) : (
            <img
              src={prefixIcon ? SearchIconTwo : SearchIcon}
              alt="search-icon"
            />
          )
        }
        onClick={() => navigate("/search")}
      />
    </div>
  );
};

export default SearchWithButton;

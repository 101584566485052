import React from "react";
import { Input } from "antd";

import "./style.css";

const InputDefault = ({ ...props }) => {
  return (
    <div>
      <Input {...props} className="defaultInput-style" />
    </div>
  );
};

export default InputDefault;
